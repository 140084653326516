/* eslint-disable prettier/prettier */
import { bytesToSize, formatPhonenumber } from "../sections/utilities/formatters";
import { NamedColorspace } from "@textea/json-viewer";
import { JsonViewer } from "@textea/json-viewer";
import { useQuery } from "@tanstack/react-query";
import Loader2 from "../sections/utilities/Loader2";
import usersService from "../services/users.service";
import companyService from "../services/company.service";
import processflowGroupService from "../services/processflow-group.service";
import businessRuleTypeService from "../services/business-rule-type.service";
import processflowService from "../services/processflow.service";
import { Fragment, useCallback, useState, useEffect } from "react";
import roleGroupService from "../services/role-group.service";
import defaultSourcesService from "../services/default-sources.service";
import ButtonNeoGen, { ButtonNeoGenTypes } from "./button";
import interactionTypesService from "../services/interaction-types.service";
import OptionsDropDown from "./options-dropdown";

const camel2title = (camelCase: string) =>
    camelCase
        .replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase())
        .trim();

function compare(a: any, b: any) {
    if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
        return -1;
    }
    if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
        return 1;
    }
    return 0;
}

export const solarized: NamedColorspace = {
    scheme: "Solarized Light",
    author: "Ethan Schoonover (modified by aramisgithub)",
    base00: "fdf6e3",
    base01: "eee8d5",
    base02: "93a1a1",
    base03: "839496",
    base04: "657b83",
    base05: "586e75",
    base06: "073642",
    base07: "002b36",
    base08: "dc322f",
    base09: "cb4b16",
    base0A: "b58900",
    base0B: "859900",
    base0C: "2aa198",
    base0D: "268bd2",
    base0E: "6c71c4",
    base0F: "d33682",
};

export const summerfruitDark: NamedColorspace = {
    scheme: "Summerfruit Dark",
    author: "Christopher Corley (http://christop.club/)",
    base00: "151515",
    base01: "202020",
    base02: "303030",
    base03: "505050",
    base04: "B0B0B0",
    base05: "D0D0D0",
    base06: "E0E0E0",
    base07: "FFFFFF",
    base08: "FF0086",
    base09: "FD8900",
    base0A: "ABA800",
    base0B: "00C918",
    base0C: "1FAAAA",
    base0D: "3777E6",
    base0E: "AD00A1",
    base0F: "CC6633",
};

export default function TableNeogen(props: TableNeogenProps) {
    const getDarkMode = useCallback(() => {
        if (localStorage.getItem("theme") !== null) {
            if (localStorage.getItem("theme") === "dark") {
                document.body.classList.add("dark-theme");
                document.body.classList.add("dark");
                return true;
            } else {
                document.body.classList.remove("dark-theme");
                document.body.classList.remove("dark");
                return false;
            }
        } else {
            return false;
        }
    }, []);
    const roleGroupQuery = useQuery(["roleGroups"], async () => {
        const response = await roleGroupService.getAll();
        if (response) {
            return response.data;
        }
    });
    const messageTypesQuery = useQuery(["messageTypes"], async () => {
        const response = await interactionTypesService.getAll();
        if (response) {
            return response.data;
        }
    });
    const defaultSourcesQuery = useQuery(["defaultSources"], async () => {
        const response = await defaultSourcesService.getAll();
        if (response) {
            return response.data;
        }
    });
    const groupQuery = useQuery(["groups"], async () => {
        const response = await processflowGroupService.getAll();
        if (response) {
            return response.data;
        }
    });
    const processflowQuery = useQuery(["processFlow"], async () => {
        const response = await processflowService.getAll();
        if (response) {
            return response.data;
        }
    });
    const usersQuery = useQuery(["users"], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data;
        }
    });

    const ruleTypeQuery = useQuery(["business-rule-types2"], async () => {
        const response = await businessRuleTypeService.getAll();
        if (response) {
            return response.data;
        } else {
            return [];
        }
    });

    const companyQuery = useQuery(
        ["companies"],
        async () => {
            const response = await companyService.getAll();
            if (response) {
                return response.data.sort(compare);
            }
        },
        {
            cacheTime: 60000,
            staleTime: 60000,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    );

    const [entries, setEntries] = useState<any[]>([]);
    const [px, setPx] = useState(3);
    const [py, setPy] = useState(3.5);
    const [pytd, setPytd] = useState(4);

    const [headers, setHeaders] = useState<string[]>([]);
    useEffect(() => {
        if (props.compact) {
            setPx(1);
            setPy(0);
            setPytd(1);
        }
    }, [props.compact]);
    useEffect(() => {
        if (!props.entries) {
            return;
        } else {
            if (!props.entries[0]) {
                return;
            }
        }
        const entries = JSON.parse(JSON.stringify(props.entries));
        const newEntries = entries.map((e: any) => {
            if (props.ignoreFields) {
                props.ignoreFields.forEach((i) => {
                    try {
                        delete e[i];
                    } catch (x) {
                        console.error(x);
                    }
                });
            }
            if (e) {
                Object.entries(e).forEach((e2) => {
                    const fo = props.formatters?.find((f) => {
                        return f.field === e2[0];
                    });
                    if (fo) {
                        switch (fo.type) {
                            case "Date":
                                e[e2[0]] = new Date(e2[1] as string).toLocaleDateString();
                                break;
                            case "Time":
                                e[e2[0]] = new Date(e2[1] as string).toLocaleTimeString();
                                break;
                            case "Datetime":
                            case "DateTime":
                                if (!e2[1]) {
                                    // e[e2[0]] = "-";

                                } else {
                                    e[e2[0]] = new Date(e2[1] as string).toLocaleString();
                                }
                                break;
                            case "Phone":
                                e[e2[0]] = formatPhonenumber(e2[1] as string);
                                break;                            
                            case "PhoneNoSpace":
                                e[e2[0]] = formatPhonenumber(e2[1] as string)?.replaceAll(" ", "");
                                break;
                            case "Boolean":
                                e[e2[0]] = e2[1] ? (
                                    <span className="fas fa-circle-check text-green-500" />
                                ) : (
                                    <span className="fas fa-circle-xmark text-red-500" />
                                );
                                break;
                            case "DisplayItem":
                                // eslint-disable-next-line no-case-declarations
                                const obj: any = e2[1] as object;
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                e[e2[0]] = obj[fo.itemToDisplay!];
                                break;
                            case "FileSize":
                                e[e2[0]] = bytesToSize(Number(e2[1]));
                                break;
                            case "User":
                                e[e2[0]] = usersQuery.data?.find((i) => i.id === e2[1])?.name;
                                break;
                            case "Company":
                                e[e2[0]] = companyQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "RuleType":
                                e[e2[0]] = ruleTypeQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "MessageType":
                                e[e2[0]] = messageTypesQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "ProcessFlowGroup":
                                e[e2[0]] = groupQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "RoleGroup":
                                e[e2[0]] = roleGroupQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "DefaultSource":
                                e[e2[0]] = defaultSourcesQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "Processflow":
                                e[e2[0]] = processflowQuery.data?.find((i) => i.id === e2[1])?.title ?? "-";
                                break;
                            case "CustomFunction":
                                e[e2[0]] = fo.customFunction?.(e2[1]);
                        }
                    }
                });
            }

            return e;
        });
        setEntries(newEntries);
        if (props.headers) {
            setHeaders(props.headers);
        } else {
            if (newEntries[0]) {
                // console.error(newEntries);
                let keys = Object.keys(newEntries[0]);
                keys = keys.map((k) => {
                    return camel2title(k);
                });
                setHeaders(keys);
            }
        }
    }, [props.entries, props.formatters, props.headers, props.ignoreFields, usersQuery.data, groupQuery.data, companyQuery.data, ruleTypeQuery.data, processflowQuery.data, roleGroupQuery.data, defaultSourcesQuery.data, messageTypesQuery.data]);

    if (!props.entries || props.entries.length === 0) {
        return <></>;
    }

    if (
        usersQuery.isLoading ||
        messageTypesQuery.isLoading ||
        companyQuery.isLoading ||
        groupQuery.isLoading ||
        ruleTypeQuery.isLoading ||
        processflowQuery.isLoading ||
        roleGroupQuery.isLoading ||
        defaultSourcesQuery.isLoading
    ) {
        return <Loader2 />;
    }

    function formatTableCell(key: string, value: unknown) {
        const typeOfField = props.formatters?.find((formatter) => formatter.field === key)?.type;
        if (typeOfField === "iconShort") {
            return (
                <>
                    <span className={"fal " + value + " mr-3"}></span>({value})
                </>
            );
        } else if (
            typeOfField === "JSON" ||
            (typeof value === "object" && value !== null && typeOfField !== "Boolean")
        ) {
            return (
                <JsonViewer
                    value={value as object}
                    quotesOnKeys={false}
                    theme={getDarkMode() ? solarized : summerfruitDark}
                    displayDataTypes={false}
                    displayObjectSize={false}
                />
                // <JsonViewer collapsed={(!(props.formatters?.find(x => x.field === key)?.expanded) && !props.expandJSON)} value={value as object} theme={getDarkMode() ? solarized : summerfruitDark} displayDataTypes={false} name={false} displayObjectSize={false} />
            );
        }
        return value as string;
    }
    return (
        <>
            <div className={(props.actions && props.actionsAreDropDown ? " pb-56 " : "")}>
                        <div
                            className={
                                "rounded-tl-xl mt-5  flex flex-col  w-full shadow min-w-full  align-middle md:px-6 lg:px-0 dark:border-2  dark:border-slate-600/60 ring-1 ring-black ring-opacity-5 " +
                    (props.compact ? " rounded " : " rounded-xl ") 
                            }
                        >
                <table className={"min-w-full divide-y divide-gray-300 dark:divide-gray-900 "}>
                                <thead
                                    className={
                                        (props.headerBg ?? "bg-gray-50  dark:bg-slate-900") +
                                        " transition-colors duration-500 " +
                                        (props.refreshing && "bg-red-50 dark:bg-gray-800")
                                    }
                                >
                                    <tr>
                                        {headers.map((h, i) => {
                                            return (
                                                <Fragment key={i}>
                                                    {h === "Id" ? (
                                                        <></>
                                                    ) : (
                                                        <th
                                                            key={i}
                                                            scope="col"
                                                            className={
                                                                "dark:text-gray-400 tracking-wider  " +
                                                                (props.compact
                                                                    ? " text-xs font-light "
                                                                    : " text-sm font-semibold  ") +
                                                                (i === 0
                                                                    ? "py-" +
                                                                    py +
                                                                    " pl-4 pr-" +
                                                                    px +
                                                                    " text-left  text-gray-900 sm:pl-6"
                                                                    : "px-" +
                                                                    px +
                                                                    " py-" +
                                                                    py +
                                                                    " text-left  text-gray-900")
                                                            }
                                                        >
                                                            {h}
                                                        </th>
                                                    )}
                                                </Fragment>
                                            );
                                        })}
                                        {/* <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                      Name
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                      Title
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                      Email
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                      Role
                                  </th>
                                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                      <span className="sr-only">Edit</span>
                                  </th> */}
                                        {props.actions && !props.actionsAreDropDown && (
                                            <>
                                                {props.actions.map((a: any, idx: number) => (
                                                    <th key={idx}></th>
                                                ))}
                                            </>
                                        )}
                                        {props.actions && props.actionsAreDropDown && (
                                            <th>
                                                Options
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white  dark:bg-slate-700 dark:divide-gray-900">
                                    {entries.map((entry, idx) => (
                                        <tr key={idx}>
                                            {Object.entries(entry).map(([key, value], idx2) => {
                                                return (
                                                    <Fragment key={key}>
                                                        {key === "id" ? (
                                                            <></>
                                                        ) : (
                                                            <td
                                                                key={key}
                                                                className={
                                                                    "" +
                                                                    "dark:text-gray-400 " +
                                                                    (props.compact
                                                                        ? " text-xs font-light "
                                                                        : " text-sm font-medium ") +
                                                                    (props.breaks?.find((x) => x.field === key)?.type ??
                                                                        " break-normal ") +
                                                                    " " +
                                                                    (props.maxWidths?.find((x) => x.field === key)
                                                                        ?.width ?? "  ") +
                                                                    " " +
                                                                    (idx2 === 0
                                                                        ? "py-" +
                                                                        pytd +
                                                                        " pl-4 pr-3  text-gray-500 sm:pl-6"
                                                                        : "px-" +
                                                                        px +
                                                                        " py-" +
                                                                        pytd +
                                                                        "  text-gray-500")
                                                                }
                                                            >
                                                                {formatTableCell(key, value)}

                                                                {/* {(key as string)} */}
                                                                {/* {(idx2 as unknown as string)} */}
                                                            </td>
                                                        )}
                                                    </Fragment>
                                                );
                                            })}
                                            {props.actions && props.actionsAreDropDown && (
                                                <td
                                                    key={idx}
                                                    className={
                                                        "text-right dark:text-gray-300 dark:bg-slate-700 " +
                                                        "px-" +
                                                        px +
                                                        " py-" +
                                                        pytd +
                                                        " text-sm text-gray-500 dark:bg-gray-800"
                                                    }
                                                >
                                                    {/* {props.actions.map((a: any, idx: number) => (
                                                    <th key={idx}></th>
                                                ))} */}
                                                    <OptionsDropDown
                                                        options={props.actions.map(i => {
                                                            if (!i.icon) {
                                                            i.icon = "";
                                                            }
                                                            return {
                                                                ...i,

                                                                action: () => {
                                                                    // alert("hi");
                                                                    i.onClick(entry.id);
                                                                }
                                                            }
                                                            // i.action = i.onClick
                                                        })}
                                                    />
                                                </td>
                                            )}
                                            {props.actions && !props.actionsAreDropDown && (
                                                <>
                                                    {props.actions.map((a: TableAction, idx: number) => (
                                                        <td
                                                            key={idx}
                                                            className={
                                                                "text-right dark:text-gray-300 dark:bg-slate-700 " +
                                                                "px-" +
                                                                px +
                                                                " py-" +
                                                                pytd +
                                                                " text-sm text-gray-500 dark:bg-gray-800"
                                                            }
                                                        >
                                                            <ButtonNeoGen
                                                                type={a.type}
                                                                className={
                                                                    (a.disabled?.(entry.id) ?? false
                                                                        ? "bg-gray-400"
                                                                        : a.className ?? "bg-indigo-600 text-white") +
                                                                    " hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2" +
                                                                    " focus:ring-indigo-500"
                                                                }
                                                                onClick={() => a.onClick(entry.id)}
                                                                disabled={a.disabled ? a.disabled(entry.id) : false}
                                                                size={a.size || props.compact ? "xxs" : "sm"}
                                                            >
                                                                <span className="whitespace-nowrap">
                                                                    {a.icon && (
                                                                        <span
                                                                            className={
                                                                                a.icon + " mr-2 whitespace-nowrap"
                                                                            }
                                                                        />
                                                                    )}
                                                                    {a.label}
                                                                </span>
                                                            </ButtonNeoGen>
                                                        </td>
                                                    ))}
                                                </>
                                            )}
                                            {/* <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                          {entry.name}
                                      </td>
                                      <td className="px-3 py-4 text-sm text-gray-500">{entry.title}</td>
                                      <td className="px-3 py-4 text-sm text-gray-500">{entry.email}</td>
                                      <td className="px-3 py-4 text-sm text-gray-500">{entry.role}</td>
                                      <td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                          <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                              Edit<span className="sr-only">, {entry.name}</span>
                                          </a>
                                      </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                </div>
            </div>
        </>
    );
}

export type TableAction = {
    label: string;
    onClick: (id: any) => void;
    className?: string;
    icon?: string;
    size?: "xxs" | "xs" | "sm" | "md" | "lg" | "xl";
    type?: ButtonNeoGenTypes,
    disabled?: (id: any) => boolean;
};

export type Formatter = {
    field: string;
    type: string;
    itemToDisplay?: string;
    expanded?: boolean;
    customFunction?: (item: any) => any;
};

export type TableNeogenProps = {
    headerBg?: string;
    entries: any[];
    headers?: string[];
    ignoreFields?: string[];
    formatters?: Formatter[];
    breaks?: any[];
    maxWidths?: any[];
    actions?: TableAction[];
    actionsAreDropDown?: boolean;
    refreshing?: boolean;
    compact?: boolean;
    expandJSON?: boolean;
};
