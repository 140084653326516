import { cx } from "@emotion/css";
import { FinanceOptions } from "../../billing/financeOptions";
import { PaymentOptions } from "../../billing/paymentOptions";
import { useBreakpoint } from "../../hooks/appMedia";
import { ButtonActionTypes } from "../../layout/button-creator";
import { Entries } from "../components/entries";
import { Stage, Stages } from "../components/stages";
import { Step, Steps } from "../components/steps";
import { Entry } from "../processflows/domain/processflow";

export const Wizard = ({
    group,
    stages,
    stageId,
    onStageChange,
    steps,
    stepId,
    entries,
    entryErrors,
    onEntryChange,
    data,
    onButtonClick,
    hideStages,
    hideSteps,
    noMargin,
    drawWithFlex,
    getFileKey,
}: {
    group?: number;
    stages: Stage[];
    stageId: number;
    onStageChange?: (stage: Stage) => void;
    steps: Step[];
    stepId?: number;
    entries: Entry[];
    entryErrors?: Record<string, string[]>;
    onEntryChange: ({ entry, value }: { entry: Entry; value: any }) => void;
    data: any;
    onButtonClick: ({ action }: { action: ButtonActionTypes }) => void;
    hideStages?: boolean;
    hideSteps?: boolean;
    noMargin?: boolean;
    drawWithFlex?: boolean;
    getFileKey?: (entry: Entry) => string;
}) => {
    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    console.error({ entries });
    return (
        <div className={cx({ flex: true, "gap-5": !isMobile }) + " min-w-full gap-1 sm:gap-5"}>
            {!hideStages && (
                <div className="mt-0 w-auto sm:w-64 md:w-96 overflow-none ">
                    <div className="md:col-span-1 hidden sm:block">
                        <div className="px-4 py-5 bg-white dark:bg-gray-800 space-y-6 sm:p-6 rounded-xl shadow-xl ">
                            <p className="text-xl font-bold text-gray-600 sm:text-xl dark:text-gray-400 mb-3">
                                Processflow Stages
                            </p>
                            <Stages stages={stages} onStageChange={onStageChange} currentStageId={stageId} />
                        </div>
                    </div>
                </div>
            )}

            <div className="flex-1 overflow-x-none">
                <div className="bg-white dark:bg-gray-800 rounded-sm sm:rounded-xl xs:block p-5 shadow-lg">
                    {!hideSteps && <Steps steps={steps} />}
                    <Entries
                        drawWithFlex={drawWithFlex}
                        onClick={(action) => {
                            onButtonClick({ action });
                        }}
                        entries={entries}
                        entryErrors={entryErrors}
                        onChange={({ entry, value }) => {
                            onEntryChange({ entry, value });
                        }}
                        data={data}
                        noMargin={noMargin}
                        getFileKey={getFileKey}
                    />
                </div>
            </div>
        </div>
    );
};
