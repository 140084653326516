import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import fileService from "../../../services/file.service";
import fileUploadService from "../../../services/file.service";
import PageDescription from "../../../layout/page-description";
// import PrintPre from "../../layout/print-pre";
import TableNeogen from "../../../layout/table-neogen";
import Loader2 from "../../utilities/Loader2";
import AddFile from "./modals/add-file";
import authService from "../../../services/auth.service";

function compare(b: any, a: any) {
    if (a.dateUploaded < b.dateUploaded) {
        return -1;
    }
    if (a.dateUploaded > b.dateUploaded) {
        return 1;
    }
    return 0;
}

export default function Files() {
    const [showAddFile, setShowAddFile] = useState(false);
    const [allFiles, setAllFiles] = useState(false);
    const queryCache = useQueryClient();

    const filesQuery = useQuery(["files"], async () => {
        if (allFiles) {
            const response = await fileUploadService.getAll();
            if (response) {
                return response.data.sort(compare);
            } else {
                return [];
            }
        } else {
            const response = await fileUploadService.getMyFiles();
            if (response) {
                return response.data.sort(compare);
            } else {
                return [];
            }
        }
    });
    useEffect(() => {
        authService.canIAccess("SEE_ALL_FILES").then((r) => {
            if (r) {
                setAllFiles(true);
                filesQuery.refetch();
            }
        });
    }, [filesQuery]);

    return (
        <>
            <PageDescription
                title="Files"
                description="Files are used to upload files to the system."
                buttons={[
                    {
                        label: "Upload File",
                        icon: "fas fa-upload",
                        onClick: () => {
                            setShowAddFile(true);
                        },
                    },
                ]}
            />
            <AddFile show={showAddFile} close={() => setShowAddFile(false)} />
            {filesQuery.isLoading ? (
                <Loader2 />
            ) : (
                <div className="">
                    <div className="mx-2">
                        <TableNeogen
                            entries={filesQuery.data ?? []}
                            headers={[
                                "Date Uploaded",
                                "File Name",
                                // "Document Type",
                                "File Size",
                                "Uploaded By",
                                "Last Updated",
                            ]}
                            formatters={[
                                {
                                    field: "dateUploaded",
                                    type: "Date",
                                },
                                {
                                    field: "documentType",
                                    type: "Mimetype",
                                },
                                {
                                    field: "lastUpdated",
                                    type: "Date",
                                },
                                {
                                    field: "size",
                                    type: "FileSize",
                                },
                                {
                                    field: "uploadedBy",
                                    type: "User",
                                },
                            ]}
                            ignoreFields={[
                                "key",
                                "isArchived",
                                "encoding",
                                "documentTypeId",
                                "notes",
                                "filename",
                                "mimetype",
                            ]}
                            actionsAreDropDown={true}
                            actions={[
                                {
                                    label: "Download",
                                    icon: "fas fa-download",
                                    onClick: (entry) => {
                                        const file = filesQuery.data?.find((file) => file.id === entry);
                                        if (file) {
                                            fileService.downloadFile(file.filename, file.originalFilename);
                                        }
                                    },
                                },
                                {
                                    label: "Delete",
                                    icon: "fas fa-trash",
                                    className: "bg-red-500 text-white",
                                    onClick: (entry) => {
                                        Swal.fire({
                                            title: "Are you sure?",
                                            text: "You won't be able to revert this!",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                        }).then((result) => {
                                            if (result.value) {
                                                fileUploadService.deleteByID(entry).then(() => {
                                                    queryCache.invalidateQueries(["files"]);
                                                    Swal.fire("Deleted!", "Your file has been deleted.", "success");
                                                });
                                            }
                                        });
                                    },
                                },
                            ]}
                        />
                    </div>
                </div>
            )}
            {/* <div className="bg-white rounded-xl  ">
                <div className="card-body p-0">
                    <div className="mt-5 pt-5 mb-5 pb-5 text-center">
                        <Loader2 />
                    </div>
                </div>
            </div> */}
        </>
    );
}
