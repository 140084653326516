/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import PrintPre from "./print-pre";

// const props.options = [
//     { name: "Public access", description: "This project would be available to anyone who has the link" },
//     { name: "Private to Project Members", description: "Only members of this project would be able to access" },
//     { name: "Private to you", description: "You are the only one able to access this project" },
// ];

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

type Entry = {
    id: number | string;
    name: string;
};

export type RadioListProps = {
    options?: (any & Entry)[];
    label?: string;
    value?: { title: string; description: string; id: number | string; current: boolean };
    onChange: (item: any & Entry) => void;
    className?: string;
    description?: string;
    ref?: React.Ref<any>;
};

export default function RadioList(props: RadioListProps) {
    return (
        <RadioGroup value={props.value} onChange={props.onChange} className={(props.className ?? "") + " mt-5"}>
            {/* <RadioGroup.Label className="text-base font-medium text-gray-900">{props.label}</RadioGroup.Label> */}
            <RadioGroup.Label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                {props.label}
            </RadioGroup.Label>
            <div className="mt-0 grid grid-cols-1 gap-y-2 sm:grid-cols-3 sm:gap-x-2">
                {props.options?.map((option) => (
                    <RadioGroup.Option
                        key={option.id}
                        value={option}
                        className={({ checked, active }) =>
                            classNames(
                                checked ? "border-transparent" : "border-gray-300",
                                active ? "border-indigo-500 ring-2 ring-indigo-500" : "",
                                "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none",
                                "hover:bg-indigo-200 dark:bg-gray-700 text-white hover:text-indigo-300    ",
                            )
                        }
                    >
                        {({ checked, active }) => (
                            <>
                                <span className="flex-1 flex">
                                    <span className="flex flex-col">
                                        <RadioGroup.Label
                                            as="span"
                                            className="block text-sm font-medium text-gray-900 group-hover:text-gray-300"
                                        >
                                            {option.name}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description
                                            as="span"
                                            className="mt-1 flex items-center text-sm text-gray-500 group-hover:text-green-500"
                                        >
                                            {option.description}
                                        </RadioGroup.Description>
                                    </span>
                                </span>
                                <CheckCircleIcon
                                    className={classNames(!checked ? "invisible" : "", "h-5 w-5 text-indigo-600")}
                                    aria-hidden="true"
                                />
                                <span
                                    className={classNames(
                                        active ? "border" : "border-2",
                                        checked ? "border-indigo-500" : "border-transparent",
                                        "absolute -inset-px rounded-lg pointer-events-none",
                                    )}
                                    aria-hidden="true"
                                />
                            </>
                        )}
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    );
}
