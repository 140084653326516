export default function ButtonNeoGen(props: ButtonNeoGenProps) {
    let classes;
    switch (props.type ?? "") {
        case "danger":
            classes = "bg-red-500 text-white dark:bg-red-700 dark:text-gray-200 ";
            break;
        case "warning":
            classes = "bg-orange-500 text-white dark:bg-orange-700 dark:text-gray-200 ";
            break;
        case "info":
            classes =
                "bg-blue-600 text-white dark:bg-blue-700 dark:text-gray-200 hover:bg-blue-700 dark:hover:bg-blue-800 ";
            break;
        case "secondary":
            classes = "bg-green-600 text-white dark:bg-green-700 dark:text-gray-200 ";
            break;
        case "outline":
            classes =
                "bg-transparent text-gray-800 dark:bg-gray-700 dark:text-gray-200 border border-gray-300 shadow dark:border-gray-800 ";
            break;
        case "outline-primary":
            classes =
                "bg-gradient-to-br from-slate-100 dark:from-gray-700 dark:to-green-800 via-indigo-50 to-gray-50 text-gray-800 dark:bg-gray-700 dark:text-gray-200 border-2 border-gray-300  shadow-xl p-10 dark:border-gray-800 ";
            break;
        case "submit":
        default:
            classes = "bg-indigo-800 hover:bg-indigo-700 text-white dark:text-gray-200 ";
    }
    switch (props.size) {
        case "xxs":
            classes +=
                "inline-flex items-center px-1 py-0.2 border border-transparent text-xxxs font-normal rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
            break;
        case "xs":
            classes +=
                "inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-normal rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
            break;
        case "sm":
            classes +=
                "inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
            break;
        case "lg":
            classes +=
                "inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
            break;
        case "xl":
            classes +=
                "inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
            break;
        default:
            classes +=
                "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
    }
    if (props.block) {
        classes += " w-full";
    }

    if (props.hidden) {
        classes += " hidden";
    }

    return (
        <button
            type={props.isSubmit || props.type === "submit" ? "submit" : "button"}
            onClick={() => props.onClick?.()}
            className={classes + " " + props.className}
            disabled={props.disabled}
        >
            <span className="flex-grow">
                {props.iconAlign !== "right" && props.icon && <i className={props.icon + " mr-3"}></i>}
                {props.text}
                {props.children}
                {props.iconAlign === "right" && props.icon && <i className={props.icon + " ml-3"}></i>}
            </span>
        </button>
    );
}
export type ButtonNeoGenTypes =
    | "danger"
    | "info"
    | "primary"
    | "submit"
    | "secondary"
    | "outline"
    | "warning"
    | "success"
    | "danger"
    | "info"
    | "primary"
    | "secondary"
    | "outline"
    | "warning"
    | "success"
    | "outline-primary";
export type ButtonNeoGenProps = {
    children?: any;
    size?: "xxxs" | "xxs" | "xs" | "sm" | "md" | "lg" | "xl";
    className?: string;
    text?: string;
    type?: ButtonNeoGenTypes;
    onClick?: () => void;
    icon?: string;
    disabled?: boolean;
    block?: boolean;
    hidden?: boolean;
    bg?: string;
    label?: string;
    iconAlign?: "left" | "right";
    isSubmit?: boolean;
};
