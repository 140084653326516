/* eslint-disable react/prop-types */
import { CheckIcon } from "@heroicons/react/24/outline";
import { ReactNode, useEffect, useState } from "react";
import authService from "../../services/auth.service";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export type Stage = {
    id: number;
    name: string;
    description: string;
    status?: "complete";
    onClick?: (id: number) => any;
};

export const Stages = ({
    stages,
    onStageChange,
    currentStageId,
}: {
    stages: Stage[];
    onStageChange?: (stage: Stage) => void;
    currentStageId?: number;
}) => {
    const ButtonOrDiv = ({ stage, children }: { stage: Stage; children: ReactNode }) => {
        return stage.onClick || onStageChange ? (
            <button
                onClick={() => {
                    if (stage.onClick) {
                        stage.onClick(stage.id);
                    }
                    if (onStageChange) {
                        onStageChange(stage);
                    }
                }}
                className="relative flex items-start group"
            >
                {children}
            </button>
        ) : (
            <div className="flex relative">{children}</div>
        );
    };

    return (
        <nav aria-label="Progress">
            <ol role="list" className="overflow-hidden">
                {stages.map((stage, stepIdx) => (
                    <li
                        key={stage.name}
                        className={classNames(stepIdx !== stages.length - 1 ? "pb-10" : "", "relative")}
                    >
                        {stage.status === "complete" ? (
                            <>
                                {stepIdx !== stages.length - 1 ? (
                                    <div
                                        className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600"
                                        aria-hidden="true"
                                    />
                                ) : null}
                                <ButtonOrDiv stage={stage}>
                                    <span className="h-9 flex items-center">
                                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                                            <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                                        </span>
                                    </span>
                                    <span className="ml-4 min-w-0 flex flex-col">
                                        <span className="text-xs text-left font-semibold tracking-wide uppercase dark:text-gray-300">
                                            {stage.name}
                                        </span>
                                        <span className="text-sm text-gray-500 dark:text-gray-400">
                                            {stage.description}
                                        </span>
                                    </span>
                                </ButtonOrDiv>
                            </>
                        ) : stage.id === currentStageId ? (
                            <>
                                {stepIdx !== stages.length - 1 ? (
                                    <div
                                        className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300 dark:bg-gray-600"
                                        aria-hidden="true"
                                    />
                                ) : null}
                                <ButtonOrDiv stage={stage}>
                                    <span className="h-9 flex items-left" aria-hidden="true">
                                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white dark:bg-gray-900 border-2 border-indigo-600 rounded-full">
                                            <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
                                        </span>
                                    </span>
                                    <span className="ml-4 min-w-0 flex flex-col">
                                        <span className="text-xs text-left font-semibold tracking-wide uppercase text-indigo-600 dark:text-green-400">
                                            {stage.name}
                                        </span>
                                        <span className="text-sm text-left text-gray-500 dark:text-gray-400">
                                            {stage.description}
                                        </span>
                                    </span>
                                </ButtonOrDiv>
                            </>
                        ) : (
                            <>
                                {stepIdx !== stages.length - 1 ? (
                                    <div
                                        className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300 dark:bg-gray-600"
                                        aria-hidden="true"
                                    />
                                ) : null}
                                <ButtonOrDiv stage={stage}>
                                    <span className="h-9 flex items-left" aria-hidden="true">
                                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white dark:bg-gray-900 dark:border-gray-700 border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                            <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                                        </span>
                                    </span>
                                    <span className="ml-4 min-w-0 flex flex-col">
                                        <span className="text-xs text-left font-semibold tracking-wide uppercase text-gray-500 dark:text-gray-300">
                                            {stage.name}
                                        </span>
                                        <span className="text-sm text-left text-gray-500 dark:text-gray-400">
                                            {stage.description}
                                        </span>
                                    </span>
                                </ButtonOrDiv>
                            </>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
};
