import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import fileUploadService from "../services/file.service";
import ButtonNeoGen from "./button";
import { FileUpload } from "../typings/api/file-upload";
import { downloadFile } from "../sections/utilities/download-file";
import PopoverComponent from "./popover-component";
import { useRecoilState } from "recoil";
import userAtom from "../atoms/userAtom";
import { ClearERCUser } from "../typings/api/clear-erc-user";
import { Label } from "./label";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

export default function FileUploadWidget(props: AddFileProps) {
    const [user, setUser] = useRecoilState<ClearERCUser>(userAtom);
    const [isReplacing, setIsReplacing] = useState(false);
    const queryCache = useQueryClient();
    async function onFileChange(event: any) {
        event.target.files[0];
        // Create an object of formData
        if (!event.target.files[0]) {
            Swal.fire({
                title: "Error",
                text: "You need to select a file",
                icon: "error",
            });
            return;
        } else {
            const res = await fileUploadService.uploadFile(event.target.files[0], user.id ?? "", props.fileKey);

            if (res && res.status === 200) {
                props.setFileDetails(res.data);
                queryCache.invalidateQueries(["files"]);
                setIsReplacing(false);
                Swal.fire({
                    title: "Success",
                    text: "File uploaded successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });
                return;
            }
        }
        Swal.fire({
            title: "Error",
            text: "File upload failed",
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
        });
    }
    return (
        <>
            <Label
                htmlFor="email"
                text={props.label || ""}
                helpDocument={props.helpDocument}
                isRequired={!!props.required}
            />
            {props.fileDetails && !isReplacing ? (
                <>
                    <div className="my-5 p-3 text-sm text-gray-700 dark:text-green-500 rounded-lg dark:bg-slate-900  bg-slate-200 flex flex-col w-full">
                        <div className="my-auto  ">{props.fileDetails.originalFilename}</div>
                        {/* <PrintPre>
                { props.fileDetails }
            </PrintPre> */}
                        <div className="flex-row flex mt-3">
                            <ButtonNeoGen
                                className=" flex-grow"
                                type="info"
                                onClick={() => setIsReplacing(true)}
                                text="Replace File"
                                size="xs"
                            />
                            <ButtonNeoGen
                                className="ml-5 flex-grow"
                                type="warning"
                                size="xs"
                                onClick={() => {
                                    return downloadFile(
                                        props.fileDetails?.filename ?? "",
                                        props.fileDetails?.originalFilename ?? "",
                                    );
                                }}
                            >
                                Download File
                            </ButtonNeoGen>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex w-full  bg-grey-lighter my-auto justify-center flex-grow">
                        <label className="w-full md:w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg  tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-blue-500 dark:bg-gray-800 border-1 my-5 shadow-lg">
                            <svg
                                className="w-8 h-8"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <span className="mt-2 text-base leading-normal">Select a file</span>
                            <input type="file" className="hidden" onChange={onFileChange} />
                        </label>
                    </div>
                </>
            )}
            {/* </PrintPre> */}
        </>
    );
}

export type FileDetails = {
    id: number;
    file: FileUpload;
};

type AddFileProps = {
    label?: string;
    name?: string;
    setFileDetails: (fileDetails: FileUpload) => void;
    fileDetails?: FileUpload;
    helpDocument?: string;
    required?: boolean;
    fileKey?: string;
};
